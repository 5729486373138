<template>
  <div id="app">
    <!-- <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.15.4/css/fontawesome.min.css" /> -->
    <Widget />
  </div>
</template>

<script>
import Widget from './components/Widget.vue';

export default {
  name: 'App',
  components: {
    Widget,
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap');

/*#app {
  background: #fc0 !important;
}*/

* {
  text-size-adjust: none;
}

*, :after, :before {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

*, ::after, ::before {
  box-sizing: border-box;
}

body, input, button {
  font-family: "Poppins", sans-serif;
}

html, body, #app {
  margin: 0;
  height: 100%;
  height: 100vh;
  pointer-events: none;
  overflow: hidden;
  background: transparent;
}

.dot-ani {
  margin-right: 5px;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: rgba(0,0,0,.32);
  display: inline-block;
  animation: bounceAnimation .2s ease-out infinite alternate;
}

.dot-ani + .dot-ani {
  animation-delay: .1s;
}

.dot-ani + .dot-ani + .dot-ani {
  animation-delay: .2s;
}

@keyframes bounceAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-4px);
  }
}
</style>
